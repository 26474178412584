// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// vitals page components
import FormField from "../FormField";

import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";

function BrandInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");

  const { formField, values, errors, touched } = formData;

  const { brandName, brandDescrption } = formField;
  var { brandName: brandNameV, brandDescrption: brandDescrptionV } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Brand Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={brandName.type}
                label=<span>{brandName.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={brandName.name}
                value={brandNameV}
                placeholder={brandName.placeholder}
                error={errors.brandName && touched.brandName}
                success={brandNameV.length > 0 && !errors.brandName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={brandDescrption.type}
                label={brandDescrption.label}
                name={brandDescrption.name}
                value={brandDescrptionV}
                placeholder={brandDescrption.placeholder}
                error={errors.brandDescrption && touched.brandDescrption}
                success={brandDescrptionV.length > 0 && !errors.brandDescrption}
                InputLabelProps={{
                  style: { paddingBottom: "2px" }}}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}></Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for BrandInfo
BrandInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default BrandInfo;
