// api.js

import axios from "../../../axiosinstance";
export const forgotPassword = async (userid) => {
    console.log("userid",userid)
  
   
  try {
    const response = await axios.get(`otps-service/api/Otps/v1/forget/password?otpOn=SMS&resourceCode=JAINA&userid=${userid}`);
    return response.data;
  } catch (error) {
    throw error;
  }
  };

 
  export const GernateNewPassword = async (formData) => {
      console.log("formData",formData)
    
     
    try {
      const response = await axios.post(`login-service-mongo/api/loginservice/v2/ForgetPassword`, formData);
      return response.data;
    } catch (error) {
      throw error;
    }
    };

  
  
//forgot passwor otp
export const conformforgotOtp = async (userid, otp) => {
   
   
  try {
    const response = await axios.post(`otps-service/api/Otps/v1/confirm?otp=${otp}&userid=${userid}`);
    return response.data;
  } catch (error) {
    throw error;
  }
  };
  
