import { useState,useEffect } from "react";

// Import form-related components and dependencies
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Import form validation, schema, and initial values
import validations from "../schemas/validations";
import form from "../schemas/form";
import initialValues from "../schemas/initialValues";
import { useNavigate } from "react-router-dom";

// Import loader component and Redux-related dependencies
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import BrandInfo from "./brandInfo";
import { createBrand } from "../service/Brand";
import { useSelector } from "react-redux";
import swal from "sweetalert";
// Function to define form steps
function getSteps() {
  return ["Brand Info"];
}

// Function to get content for each form step
function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <BrandInfo formData={formData} />;
    default:
      return null;
  }
}

// Create the CreateBrand functional component
function CreateBrand() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
   const {error,loading} = useSelector((state) => state.brand); 

  // Function to simulate a delay
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // Function to handle going back to the previous step
  const handleBack = () => setActiveStep(activeStep - 1);
  const dispatch = useDispatch();

  // Function to submit the form
  const submitForm = async (values, actions) => {
    
    console.log("Form values:", values);

  const requestData = {
    desc: values.brandDescrption.trim(),
    name: values.brandName.trim(),
    recstatus: "OPEN",
    resourcecode: "JAINA",
    status: "Active",
    syncstatus: "SYNCED",
    type: "BRAND",
  };

  try {
    // Dispatch the createBrand action and wait for the response
    const response = await dispatch(createBrand(requestData));
    console.log(response);

    // Reset the form after successful submission
    actions.setSubmitting(false);
    actions.resetForm();
    setActiveStep(0);

    // Navigate to the list page on success
    if (!response.error && !loading) {
      navigate("/app/BrandList/brand-list");
    }
  } catch (error) {
    console.error("Error submitting form:", error);
    // Handle any error from the API call here
    actions.setSubmitting(false);
  }
     
     
   
  };

  // Function to handle form submission
  const handleSubmit = (values, actions) => {
    console.log("Form values:", values);

    if (isLastStep) {
      // If it's the last step, submit the form
      submitForm(values, actions);
    } else {
      // If not the last step, move to the next step
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };
  useEffect(() => {
    if (error) {
      swal({
        icon: 'error',
        title: 'Error',
        text: error.message,
      });
    }
  }, [error]);

  // Render the component
  return (
    <DashboardLayout>
      <DashboardNavbar />
    
   
    
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                          Brand Master
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={5}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              Back
                            </MDButton>
                          )}
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="dark"
                            disabled={isLoading}
                          >
                            {loading ? (
                              <MoonLoader color="#f2fefa" size={16} />
                            ) : (
                              "Submit"
                            )}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CreateBrand;
