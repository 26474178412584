import React, { useState } from "react";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import swal from "sweetalert";
import Grid from "@mui/material/Grid";

import { useFormik } from "formik";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { validationdata } from "./schemas";

// Add imports for Select and MenuItem
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Link } from "react-router-dom";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

import { useLocation } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { GernateNewPassword } from "app/Forgot Password/service/Forgotpassword";

// import { makeApiRequest } from "./api";

// Add a state variable for the selected title
const initialValues = {
  mpin: "",
  confirm_mpin: "",
  
};

const NewMpin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location",location.state)
  const[data,setData] = useState(location.state?.responseData?.data.dto)
  console.log("1222",location?.state?.responseData?.data?.dto)
  const [isLoading, setIsLoading] = useState(false); //loader

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: validationdata,
      onSubmit: async (values, action) => {
        console.log("newmpin",values)
        setIsLoading(true);
        
        try {
          // Call the API function with form values
          const requestData = {
          
            newpassword: values.mpin,
            resourcecode: "JAINA",
            username: data.userid
          };
          const responseData = await GernateNewPassword(requestData);
console.log(responseData)
          // Handle the response data as needed
          swal("Good job!", "Your Password has been successfully changed!", "success")
          navigate("/authentication/sign-in/basic");
       

          // Reset the form after successful submission
          action.resetForm();
        }
         catch (error) {
          // Handle any API errors or other errors
         
          swal("Oops!", error.response?.data?.aceErrors[0]?.errorMessage, "warning");
        } 
        finally {
          setIsLoading(false); // Reset isLoading to false when submission is complete
        }
       
      },
    });
  // Function to handle changes in the selected title
 
  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-1}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={2}>
           Change Mpin
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your mpin to get new one
          </MDTypography>
        </MDBox>

        <form onSubmit={handleSubmit}>
          <MDBox pb={3} px={3}>
          
           {/* MPIN Input */}
            <Grid item xs={12} mt={1.5}>
              <MDInput
                fullWidth
                name="mpin"
                id="mpin"
                type="number"
                label="MPIN"
                autoComplete="off"
                value={values.mpin}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.mpin && touched.mpin && (
                <p
                  style={{
                    fontWeight: "400",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {errors.mpin}
                </p>
              )}
            </Grid>

            {/* Confirm MPIN Input */}
            <Grid item xs={12} mt={1.5}>
              <MDInput
                fullWidth
                name="confirm_mpin"
                id="confirm_mpin"
                type="number"
                autoComplete="off"
                label="Confirm MPIN"
                value={values.confirm_mpin}
                onBlur={handleBlur}
                onChange={handleChange}
              />

              {errors.confirm_mpin && touched.confirm_mpin && (
                <p
                  style={{
                    fontWeight: "400",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {errors.confirm_mpin}
                </p>
              )}
            </Grid>

            <Grid container spacing={3} mt={1}>
              <Grid item xs={12} sm={3} mb={5}>
          
                <MDButton type="submit" variant="gradient" color="dark" style={{position:"absolute",right:"8%"}}>
                  {isLoading ? (
                    <MoonLoader color="#f2fefa" size={16} />
                  ) : (
                    "Submit"
                  )}
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </form>
       
      </Card>
    </CoverLayout>
  );
};



export default NewMpin;
