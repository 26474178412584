// import { useState, useEffect } from "react";
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
// import MDInput from "components/MDInput";
// import MDBox from "components/MDBox";
// import { getBrand, updateBrand } from "../service/Brand";
// import { useDispatch } from "react-redux";

// const Brandedit = (props) => {
//     const dispatch = useDispatch();
//     const { title, selectedBrand, openpopup, setopenpopup } = props;
//     const [editedName, setEditedName] = useState("");
//     const [editedDescription, setEditedDescription] = useState("");

//     useEffect(() => {
//         // Update the editedName and editedDescription when selectedBrand changes
//         setEditedName(selectedBrand?.name || "");
//         setEditedDescription(selectedBrand?.desc || "");
//     }, [selectedBrand]);

//     const handleClose = () => {
//         setopenpopup(false);
//     };

//     const updateData = () => {
//         // Create a copy of selectedBrand with updated values
//         const updatedBrand = {
//             ...selectedBrand,
//             name: editedName,
//             desc: editedDescription,
//         };

//         // Dispatch the action to update the brand in the Redux store
//         dispatch(updateBrand(updatedBrand));

//         // Close the dialog
//         dispatch(getBrand());
//         setopenpopup(false);
//     };

//     const handleNameChange = (event) => {
//         // Update the editedName state when the input value changes
//         setEditedName(event.target.value);
//     };

//     const handleDescriptionChange = (event) => {
//         // Update the editedDescription state when the input value changes
//         setEditedDescription(event.target.value);
//     };

//     return (
//         <Dialog open={openpopup} onClose={handleClose} >
//             <DialogTitle>{title}</DialogTitle>
//             <DialogContent style={{ width: 550, height: 150 }}>
//                 <MDBox mb={2}>
//                     <MDInput
//                         type="text"
//                         label="Brand Name"
//                         variant="standard"
//                         fullWidth
//                         value={editedName}
//                         onChange={handleNameChange}
//                     />
//                 </MDBox>
//                 <MDBox mb={2}>
//                     <MDInput
//                         type="text"
//                         label="Brand Description"
//                         variant="standard"
//                         fullWidth
//                         value={editedDescription}
//                         onChange={handleDescriptionChange}
//                     />
//                 </MDBox>
//             </DialogContent>
//             <DialogActions>
//                 <Button onClick={handleClose}>Cancel</Button>
//                 <Button onClick={updateData} >
//                     Update
//                 </Button>
//             </DialogActions>
//         </Dialog>
//     )
// }

// export default Brandedit;

import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { getBrand, updateBrand } from "../service/Brand";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

const Brandedit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedBrand, openpopup, setopenpopup } = props;

  // Validation schema using Yup (only for "Brand Name")
  const validationSchema = Yup.object({
    name: Yup.string().required("Brand Name is required"),
  });

  // Formik form setup
  const formik = useFormik({
    initialValues: {
      name: selectedBrand?.name || "",
      description: selectedBrand?.desc || "",
    },
    validationSchema,
    onSubmit: (values) => {
      // Create a copy of selectedBrand with updated values
      const updatedBrand = {
        ...selectedBrand,
        name: values.name,
        desc: values.description,
      };

      // Dispatch the action to update the brand in the Redux store
      dispatch(updateBrand(updatedBrand));

      // Close the dialog
      dispatch(getBrand());
      setopenpopup(false);
    },
  });

  useEffect(() => {
    // Update the form values when selectedBrand changes
    formik.setValues({
      name: selectedBrand?.name || "",
      description: selectedBrand?.desc || "",
    });
  }, [selectedBrand]);

  const handleClose = () => {
    setopenpopup(false);
  };

  return (
    <Dialog open={openpopup} onClose={handleClose}>
      <DialogTitle
        style={{
          backgroundColor: "#D91029",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent style={{ maxwidth: 550, height: 170 }}>
          <MDBox mb={2} mt={1}>
            <MDInput
              type="text"
              label="Brand Name"
              variant="standard"
              fullWidth
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={
                formik.touched.name && formik.errors.name ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.name}
                  </p>
                ) : null
              }
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Brand Description"
              variant="standard"
              fullWidth
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              InputLabelProps={{
                style: { paddingBottom: "2px" },
              }}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Update</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Brandedit;
