import { createSlice } from "@reduxjs/toolkit";
import { getDelar } from "../server/delar";




const initialState = {
  delar: [],
  loading: false,
  error: null,
};

export const delarDetail = createSlice({
  name: "delarDetail",
  initialState,
  extraReducers: {
   
   
   
    [getDelar.pending]: (state) => {
      state.loading = true;
    },
    [getDelar.fulfilled]: (state, action) => {
      state.loading = false;
      state.delar = action.payload.data.dtoList;
    },
    [getDelar.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    
  },
});

export default delarDetail.reducer;
