import * as Yup from "yup";
import checkout from "../schemas/form";
const {
  formField:{brandName, roleDescrption},
} = checkout;

const validations = [
  Yup.object().shape({
    [brandName.name]: Yup.string().required(brandName.errorMsg),
   // [roleDescrption.name]: Yup.string().required(roleDescrption.errorMsg),
  }),
];

export default validations;
