// api.js

import axios from "../../../axiosinstance";



export const createOtp = async (formData) => {
    console.log("formData",formData)
   
  try {
    const response = await axios.post(`otps-service/api/Otps/v1/create`, formData);
    return await response.data;
  } catch (error) {
    throw error;
  }
};

export const confirmOtp = async (userid, otp) => {
  console.log("userid",userid)
  console.log("otp",otp)
 
try {
  const response = await axios.post(`otps-service/api/Otps/v1/confirm?msgType=CONFIRM&otp=${otp}&userid=${userid}`);
  return response.data;
} catch (error) {
  throw error;
}
};

//forgot passwor otp
export const conformforgotOtp = async (userid, otp) => {
  console.log("userid",userid)
  console.log("otp",otp)
 
try {
  const response = await axios.post(`otps-service/api/Otps/v1/confirm?otp=${otp}&userid=${userid}`);
  return response.data;
} catch (error) {
  throw error;
}
};
