import { createSlice } from "@reduxjs/toolkit";
import { createBrand, deleteBrand, getBrand, updateBrand } from "../service/Brand";



const initialState = {
  brand: [],
  loading: false,
  error: null,
};

export const brandDetail = createSlice({
  name: "brandDetail",
  initialState,
  extraReducers: {
    [createBrand.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createBrand.fulfilled]: (state, action) => {
      console.log("state",state.brand)
      state.loading = false;
      state.brand.push(action.payload.data.dto);
    },
    [createBrand.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getBrand.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getBrand.fulfilled]: (state, action) => {
      state.loading = false;
      state.brand = action.payload.data.dtoList;

    },
    [getBrand.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteBrand.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteBrand.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.brand = state.brand.filter((brand) => brand.id !== id);
      }
    },
    [deleteBrand.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateBrand.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateBrand.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.brand.findIndex(brand => brand.id === action.payload.data.dto.id)
      state.brand[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateBrand.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default brandDetail.reducer;
